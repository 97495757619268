<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="封面图">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.cover" class="dialog-img" :src="ruleForm.cover" />
							<p v-if="ruleForm.cover" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="轮播图">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList.length>0" v-for="(item,index) in imgList" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="一级分类">
						<span class="xx">*</span>
						<el-select clearable filterable v-model="ruleForm.one_category_id" placeholder="请选择一级分类(可搜索)" @change="change">
							<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="二级分类" v-if="ruleForm.one_category_id">
						<span class="xx">*</span>
						<el-select clearable filterable v-model="ruleForm.two_category_id" placeholder="请选择二级分类(可搜索)">
							<el-option v-for="item in erlist" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="原价">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.original_price" placeholder="请输入原价"></el-input>
					</el-form-item>
					<el-form-item label="销量">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.sale" placeholder="请输入销量"></el-input>
					</el-form-item>
					<el-form-item label="状态">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.status" placeholder="请选择状态">
							<el-option label="上架" :value="1"></el-option>
							<el-option label="下架" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<!-- 图文详情 -->
			<content ref="content" @Submitinput="Submitinput">
			</content>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/good";
	import content from '@/components/content.vue'
	export default {
		components: {
			content
		},
		data() {
			return {
				Headers: {
					token: ''
				},
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				ruleForm: {
					name: '',
					one_category_id: '',
					two_category_id: '',
					cover: '',
					slider_image: '',
					sort: 0,
					sale: Math.floor(Math.random()*1000),
					detail: '',
					status: 1,
					is_recommend: 1

				},
				imgList: [],
				list: [],
				erlist: []
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 获取二级分类
			geterList(id) {
				this.$http.post(api.TypeList, {
						page: 1,
						limit: 9999,
						pid: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.erlist = res.data.list
						}
					})
			},
			// 获取一级分类
			change(e) {
				this.geterList(e)
			},
			getList() {
				this.$http.post(api.TypeList, {
						page: 1,
						limit: 9999,
						pid: 0
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.list
						}
					})
			},
			// 删除轮播图片
			deleImg(index) {
				this.imgList.splice(index, 1)
			},
			// 轮播图片
			imgSuccess(res, file) {
				this.imgList.push(res.data.url)
			},
			// 删除图片
			dele() {
				this.ruleForm.cover = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.cover = res.data.url
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.detail = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.imgList = res.data.slider_image ? res.data.slider_image.split(",") : []
							this.geterList(res.data.one_category_id)
							// 赋值
							this.$refs.content.setContent(res.data.detail)
							delete this.ruleForm.create_time
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				this.title = val
				this.getList()
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				this.imgList = []
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.ruleForm.sale = Math.floor(Math.random()*1000)
				this.ruleForm.status = 1
				this.ruleForm.sort = 0
				this.$refs.content.emptyContent()
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				this.ruleForm.slider_image = this.imgList.join(",")
				if (this.ruleForm.cover === '') {
					ElMessage({
						message: '请上传封面图',
						type: 'warning',
					})
				}
				if (this.ruleForm.slider_image === '') {
					ElMessage({
						message: '请上传轮播',
						type: 'warning',
					})
				} else if (this.ruleForm.one_category_id === '') {
					ElMessage({
						message: '请选择一级分类',
						type: 'warning',
					})
				} else if (this.ruleForm.two_category_id === '') {
					ElMessage({
						message: '请选择二级分类',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.original_price === '') {
					ElMessage({
						message: '请输入原价',
						type: 'warning',
					})
				} else if (this.ruleForm.sale === '') {
					ElMessage({
						message: '请输入销量',
						type: 'warning',
					})
				} else if (this.ruleForm.status === '') {
					ElMessage({
						message: '请选择状态',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>

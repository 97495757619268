import request from './request'

const api = {
	// 获取列表
	lists: '/mall/Product/list',
	// 添加
	add: '/mall/Product/create',
	// 删除
	dele: '/mall/Product/delete',
	// 编辑
	edit: '/mall/Product/update',
	// 详情
	info: '/mall/Product/read',
	// 分类
	TypeList: '/mall/Category/list',
	// 村
	cunlist: '/village/lists',
}

export {
	request,
	api
}
